import React from 'react';
import './Loadinganimation.css';
import loadingImage from './images/loading.svg';

function LoadingAnimation() {
  return (
    <div className="loading-animation">
      <img src={loadingImage} alt="Loading..." className="loading-image" loading="lazy"/>
    </div>
  );
}

export default LoadingAnimation;
