import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadingAnimation from './components/Loadinganimation';

// Define components with lazy loading
const Home = React.lazy(() => import('./components/pages/Home'));
const Ouroffering = React.lazy(() => import('./components/pages/Ouroffering'));
const Aboutus = React.lazy(() => import('./components/pages/Aboutus'));
const Media = React.lazy(() => import('./components/pages/Media'));
const MediaPageOne = React.lazy(() => import('./components/pages/Mediapageone'));
const MediaPageTwo = React.lazy(() => import('./components/pages/Mediapagetwo'));
const MediaPageThree = React.lazy(() => import('./components/pages/Mediapagethree'));
const ContactusPage = React.lazy(() => import('./components/pages/Contactus'));

function App() {
  const [cachedData, setCachedData] = useState({});

  useEffect(() => {
    // Load cached data from localStorage on component mount
    const cachedDataFromStorage = localStorage.getItem('cachedData');
    if (cachedDataFromStorage) {
      setCachedData(JSON.parse(cachedDataFromStorage));
    }
  }, []);

  // Function to cache data in localStorage
  const cacheData = (key, data) => {
    setCachedData(prevState => ({ ...prevState, [key]: data }));
    localStorage.setItem('cachedData', JSON.stringify(cachedData));
  };

  return (
    <>
      <Router>
        <Suspense fallback={<LoadingAnimation />}> {/* Wrap Routes with Suspense */}
          <Routes>
            {/* Pass cacheData function to each route */}
            <Route path="/" element={<Home cacheData={cacheData} cachedData={cachedData} />} />
            <Route path="/home" element={<Home cacheData={cacheData} cachedData={cachedData} />} />
            <Route path="/offering" element={<Ouroffering cacheData={cacheData} cachedData={cachedData} />} />
            <Route path="/aboutus" element={<Aboutus cacheData={cacheData} cachedData={cachedData} />} />
            <Route path="/media" element={<Media cacheData={cacheData} cachedData={cachedData} />} />
            <Route path="/media/1" element={<MediaPageOne cacheData={cacheData} cachedData={cachedData} />} />
            <Route path="/media/2" element={<MediaPageTwo cacheData={cacheData} cachedData={cachedData} />} />
            <Route path="/media/3" element={<MediaPageThree cacheData={cacheData} cachedData={cachedData} />} />
            <Route path="/contactus" element={<ContactusPage cacheData={cacheData} cachedData={cachedData} />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
